<div fxLayout="row" fxLayoutAlign="start start" *ngIf="initialized">
	<div fxLayout="column" fxLayoutAlign="space-between start" fxFlex="100" fxFlex.xs="95">
		<div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFill>
			<div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px">
				<h3>{{crud.name}}</h3>
				<p>{{crud.description}}</p>
			</div>
			<!-- <div fxLayout="row" fxLayoutGap="15px">

				<button *ngIf="crud.options?.length > 0" mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>

				<mat-menu #menu="matMenu">
					<button mat-menu-item *ngFor="let option of crud.options" (click)="option.action()">
						<span>{{option.label}}</span>
					</button>
				</mat-menu>

			</div> -->
			<!-- <ng-container [ngTemplateOutlet]="deleteButton" *ngIf="!hiddenBulkDelete"></ng-container> -->
		</div>

		<!-- <app-mat-table ($buttonClicked)=clickAction($event) [cssClass]="'mat-elevation-z0'" [table]="crud.table" fxFill
			#tableClass [styleOptions]="{ 'borderRadius': true }" [expandable]="expandable" [columnLimit]="columnLimit"
			[hideNewButton]="hideNewButton" [showColDots]="showColDots" [hiddenSearch]="hiddenSearch"
			[hideDeletedRowsButton]="showDeleteRows" [hideTableFooter]="hideTableFooter">
		</app-mat-table> -->

		<app-paging-search *ngIf="!hiddenSearch" [hideFilter]="hideFilter" fxFlex
			[paging]="crud.table.paging"></app-paging-search>

		<!-- <div fxLayout="row" fxLayoutAlign="start center" class="position-btn" *ngIf="!template && !hideNewButton; else template"> -->
		<div fxLayout="row" fxLayoutAlign="start center" class="position-btn">
			<button mat-flat-button class="generic-btn bg-primary" (click)="clickAction()">+ New</button>
		</div>

		<ng-container *ngIf="isMenu">

			<mat-card *ngFor="let item of crud.table.paging.response.data" fxLayout="row" fxLayoutAlign="start start"
				fxLayoutGap="10px" class="w-100 card-menu mat-elevation-z0">
				<div class="pointer" (click)="redirectToMenu(item.qrCode)">
					<qrcode #qrElement [elementType]="elementType" [qrdata]="item?.qrCode" [allowEmptyString]="true" [width]="512"
						[title]="'Your menu'"></qrcode>
				</div>

				<div fxLayout="column" class="menu-info w-100">
					<h3>{{item?.name}}</h3>
					<small>{{item?.categories.length}} categories</small>
				</div>

				<button mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>

				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="editModel(item)">
						<mat-icon color="primary">edit a</mat-icon>
						<span>Edit</span>
					</button>
					<button mat-menu-item (click)="$event.stopPropagation(); destroyModel(item)">
						<mat-icon color="primary">delete</mat-icon>
						<span>Remove</span>
					</button>
					<button mat-menu-item (click)="copyUrl(item?.qrCode)">
						<mat-icon color="primary">content_copy</mat-icon>
						<span>Copy URL</span>
					</button>
					<button mat-menu-item (click)="saveQaAsImage(qrElement)">
						<mat-icon color="primary">download</mat-icon>
						<span>Download QR</span>
					</button>
				</mat-menu>
			</mat-card>

		</ng-container>

		<ng-container *ngIf="isCategory">
			<mat-card *ngFor="let item of crud.table.paging.response.data" fxLayout="row" fxLayoutAlign="start start"
				fxLayoutGap="10px" class="w-100 card-menu mat-elevation-z0">

				<img *ngIf="item.promotion" class="image" src="{{item?.image}}" alt="banner_promotion">

				<div fxLayout="column" class="menu-info w-100">
					<h3>{{item?.name}}</h3>
					<small>{{item?.dishes.length}} dishes</small>
				</div>

				<button mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>

				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="editModel(item)">
						<mat-icon color="primary">edit a</mat-icon>
						<span>Edit</span>
					</button>
					<button mat-menu-item (click)="$event.stopPropagation(); destroyModel(item)">
						<mat-icon color="primary">delete</mat-icon>
						<span>Remove</span>
					</button>
				</mat-menu>
			</mat-card>
		</ng-container>

		<ng-container *ngIf="isDish">
			<mat-card *ngFor="let item of crud.table.paging.response.data" fxLayout="row" fxLayoutAlign="start start"
				fxLayoutGap="10px" class="w-100 card-menu mat-elevation-z0">

				<div class="image-dish-container">
					<img class="image" loading="lazy" [src]="item?.coverFile" alt="dish_cover"
						(error)="onError($event)">
				</div>

				<div fxLayout="column" class="menu-info w-100">
					<h3>{{item?.name}}</h3>
					<h4>${{item?.price}}</h4>

					<div>
						<b>Tags: </b><span>{{item?.tags.length}}</span>
						<!-- <ng-container *ngFor="let tag of item?.tags.length">
							{{tag?.name}}
						</ng-container> -->
						<br>

						<b>Allergies: </b><span>{{item?.allergies.length}}</span>
						<!-- <ng-container *ngFor="let tag of item?.allergies;">
							{{tag?.name}}
						</ng-container> -->
					</div>
				</div>

				<button mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>

				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="editModel(item)">
						<mat-icon color="primary">edit a</mat-icon>
						<span>Edit</span>
					</button>
					<button mat-menu-item (click)="$event.stopPropagation(); destroyModel(item)">
						<mat-icon color="primary">delete</mat-icon>
						<span>Remove</span>
					</button>
				</mat-menu>
			</mat-card>
		</ng-container>

		<!-- <button *ngIf="canCreate && allowCreate && activeOptions?.active" mat-button color="primary"
			(click)="emitRows()">
			<mat-icon>add</mat-icon>
			<span>Add to {{ activeOptions?.addToLabel }}</span>
		</button> -->

	</div>

</div>

<app-paginator [paging]="crud.table.paging" [marginRight]="false" [hidePageSize]="crud.table.hidePageSize"
	fxFlexAlign="end">
</app-paginator>

<br>

<ng-template #options let-item="item" let-dataSource="dataSource">
	<div [fxLayout]="additionalCrudOptions?.visibility && additionalCrudOptions?.copy ? 'row' : ''" fxLayoutGap="10px">

		<ng-container [ngTemplateOutlet]="optionsBefore" [ngTemplateOutletContext]="{ item }">
		</ng-container>

		<mat-icon [ngClass]="additionalCrudOptions.visibility ? 'pointer' : 'pointer edit-margin'" mat-icon-button
			color="primary" (click)="$event.stopPropagation(); editModel(item)">
			edit a
		</mat-icon>
		<!-- <mat-icon [ngClass]="additionalCrudOptions.visibility ? 'pointer' : 'pointer edit-margin'"
			*ngIf="(canEdit && !item?.isDeleted && !additionalCrudOptions?.dynamicEdit) || (item?.startDateTime?.seconds >= currentTime && additionalCrudOptions?.dynamicEdit)"
			mat-icon-button color="primary" (click)="$event.stopPropagation(); editModel(item)">
			edit a
		</mat-icon> -->

		<!-- <div *ngIf="!((canEdit && !item?.isDeleted && !additionalCrudOptions?.dynamicEdit) || (item?.startDateTime?.seconds >= currentTime && additionalCrudOptions?.dynamicEdit))"
			class="emptyIcon">
		</div> -->

		<mat-icon class="pointer" mat-icon-button color="warn"
			(click)="$event.stopPropagation(); destroyModel(item)">delete</mat-icon>
		<!-- <mat-icon class="pointer" *ngIf="canRemove && !item?.isDeleted && !item?.__isRemovable && !hideDeleteRow"
			mat-icon-button color="warn" (click)="$event.stopPropagation(); destroyModel(item)">delete</mat-icon> -->

		<!-- <div *ngIf="!(canRemove && !item?.isDeleted && !hideDeleteRow && !item?.__isRemovable)" class="emptyIcon">
		</div> -->

		<ng-container [ngTemplateOutlet]="optionsAfter" [ngTemplateOutletContext]="{ item }">
		</ng-container>
	</div>
</ng-template>

<!-- <ng-template #bulkDelete let-item="item" let-dataSource="dataSource">
	<mat-checkbox [checked]="isRowChecked(item, true)" (change)="addOrRemoveRowsToDelete(item)" class="margin-check-box"
		*ngIf="!hiddenBulkDelete"></mat-checkbox>
</ng-template>

<ng-template #activeColumn let-item="item" let-dataSource="dataSource">
	<mat-checkbox [checked]="isRowChecked(item)" (change)="addOrRemoveRows(item)"></mat-checkbox>
</ng-template>

<ng-template #deleteButton>
	<button [disabled]="rowsSelectedToDelete.length == 0" class="bulkDelete" mat-icon-button color="danger"
		(click)="deleteItems()">
		<mat-icon>delete</mat-icon>
	</button>
</ng-template> -->

<!-- <ng-template #menu>
	<mat-card *ngFor="let item of crud.table.paging.response.data" fxLayout="row" fxLayoutAlign="start start"
		fxLayoutGap="10px" class="w-100 card-menu mat-elevation-z0">
		<div>
			<qrcode [qrdata]="item?.qrCode" [allowEmptyString]="true" [title]="'Your menu'"></qrcode>
		</div>

		<div fxLayout="column" class="menu-info w-100">
			<h3>{{item?.name}}</h3>
			<small>{{item?.categories.length}} categories</small>
		</div>

		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>

		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="editModel(item)">
				<mat-icon color="primary">edit a</mat-icon>
				<span>Edit</span>
			</button>
			<button mat-menu-item (click)="$event.stopPropagation(); destroyModel(item)">
				<mat-icon color="primary">delete</mat-icon>
				<span>Remove</span>
			</button>
		</mat-menu>
	</mat-card>
</ng-template>

<ng-template #category></ng-template>

<ng-template #dish></ng-template> -->
